import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../contexts/authContext";
import { useNavigate } from "react-router-dom";
import useSortableData from "../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../PaginationButtons";
import useDarkMode from "../../hooks/useDarkMode";
import { useFormik } from "formik";
import { getColumnsForDataTable, getCurrentDateAndTime, onlyAllowNumber, pictNotLoading, profilePic, updateColumnsForDataTable, updateFilter } from "../../services/common.service";
import { deleteStaffDetails, deleteStaffDetailsForCampus, getProfileForStaff, getStaffListByUserTypeId, getStaffSalaryDetails, updateStaffPfDetails, updateStaffResigningDetails, updateStaffResigningDetailsForCampus, updateStaffRole, updateStaffRoleForCampus } from "../../services/staff.service";
import { toasts } from "../../services/toast.service";
import { getDepartmentList, getStaffDesignation } from "../../services/setting.services";
import { getLicenseKey } from "../../services/application.settings";
import { TableLoader, showLoader } from "../../services/loader.services";
import { useReactToPrint } from "react-to-print";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import Page from "../../layout/Page/Page";
import Collapse from "../bootstrap/Collapse";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../bootstrap/Card";
import Button, { ButtonGroup } from "../bootstrap/Button";
import FormGroup from "../bootstrap/forms/FormGroup";
import SearchableSelect from "../../common/components/SearchableSelect";
import OpenCardComponent from "../../common/components/OpenCardComponents";
import Icon from "../icon/Icon";
import Input from "../bootstrap/forms/Input";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../bootstrap/Dropdown";
import Checks, { ChecksGroup } from "../bootstrap/forms/Checks";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../services/export.service";
import NoDataMsg from "../../common/components/NoDataMsg";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../bootstrap/OffCanvas";
import Textarea from "../bootstrap/forms/Textarea";
import AlertService from "../../services/AlertService";
import DeleteComponents from "../../common/components/DeleteComponents";
import { getUserType } from "../../services/userType.service";
import Badge from "../bootstrap/Badge";
import { addStaffSalaryDetails, getSalaryTemplateList } from "../../services/salary.service";

const StaffList = () => {

    useEffect(() => {

        getUserTypeList()
        getDepartment()
    }, [])

    const { userAccountId, userTypeId, organisationDetailsId, isPayrollConnected } = useContext(AuthContext);
    const navigate = useNavigate();
    const [staffDetailsId, setStaffDetailsId] = useState('')
    const [staffTypeId, setStaffTypeId] = useState<any>({ value: 0, label: 'Select All' })
    const [allUserTypeData, setAllUserTypeData] = useState<any>([])
    const [userTypeData, setUserTypeData] = useState<any>([])
    const [staffDetailsData, setStaffDetailsData] = useState([])
    const [staffProfileData, setStaffProfileData] = useState<any>([])
    const [dataSuccess, setDataSuccess] = useState(false)

    // ForModal
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(staffDetailsData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [isLoader, setIsLoader] = useState(false)

    //Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')

    const [updateRoleCanvas, setUpdateRoleCanvas] = useState(false)
    // const [addStaffSalaryCanvas, setAddStaffSalaryCanvas] = useState(false)
    const [deleteStaffListCanvas, setDeleteStaffListCanvas] = useState(false)
    const [exitOrResignCanvas, setExitOrResignCanvas] = useState(false);
    const [staffPfDetailsCanvas, setStaffPfDetailsCanvas] = useState(false);

    const [relievingTypeId, setRelievingTypeId] = useState<any>('')
    const [relievingTypeIdValue, setRelievingTypeIdValue] = useState<any>('')
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const [staffDesignationId, setStaffDesignationId] = useState<any>('')
    const [departmentId, setDepartmentId] = useState<any>('')
    const [statusId, setStatusId] = useState<any>('')
    const [staffDesignationData, setStaffDesignationData] = useState<any>([])
    const [departmentData, setDepartmentData] = useState<any>([])
    const [staffPfDetailsData, setStaffPfDetailsData] = useState<any>([])
    const [designationShow, setDesignationShow] = useState(false)
    const [isPfContribution, setIsPfContribution] = useState(false)
    // const [staffSalaryDetailsShowCanvas, setStaffSalaryDetailsShowCanvas] = useState(false)

    // const [salaryTemplateId, setSalaryTemplateId] = useState<any>('');
    // const [annualCTC, setAnnualCTC] = useState<any>('');

    const deleteStaffListForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            remarks: '',
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    })

    const updateRoleForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            staffTypeId: '',
            staffDesignationId: '',
            departmentId: '',
        },
        validate: () => { },
        //validateOnChange: false,
        onSubmit: () => { addStaffRoleSubmit() },
    })

    const exitOrResignForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            resignedDate: '',
            remarks: '',
            terminationDate: '',
            terminationReason: '',
        },
        validate: (values) => {
            const errors: {
                resignedDate?: string;
                terminationDate?: string;
            } = {};

            if (relievingTypeId == "1") {
                if (!values.resignedDate) {
                    errors.resignedDate = 'Required'
                }
            }
            else {
                if (!values.terminationDate) {
                    errors.terminationDate = 'Required'
                }
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: () => { exitOrResignSubmit() },
    })

    const updateStaffPfForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            epfNumber: '',
            remarks: '',
            basicSalary: '',
            employeeContribution: '',
            employerContribution: '',
            adminCharges: '',
            totalPfAmount: '',
        },
        validate: (values) => {
            const errors: {
                epfNumber?: string;
                // remarks?: string;
                basicSalary?: string;
                employeeContribution?: string;
                employerContribution?: string;
                adminCharges?: string;
                totalPfAmount?: string;
            } = {};
            if (!values.epfNumber) {
                errors.epfNumber = 'Required'
            }
            if (!values.basicSalary) {
                errors.basicSalary = 'Required'
            }
            if (!values.employeeContribution) {
                errors.employeeContribution = 'Required'
            }
            if (!values.employerContribution) {
                errors.employerContribution = 'Required'
            }
            if (!values.adminCharges) {
                errors.adminCharges = 'Required'
            }
            // if (!values.totalPfAmount) {
            //     errors.totalPfAmount = 'Required'
            // }
            // if (!values.remarks) {
            //     errors.remarks = 'Required'
            // }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { updateStaffPfSubmit() },
    })

    // const salaryInfoForm = useFormik({
    //     initialValues: {
    //         salaryTemplateId: '',
    //         annualCTC: '',
    //         basicCtcPercentage: '',
    //     },
    //     validate: (values) => {
    //         const errors: {
    //             salaryTemplateId?: string;
    //             annualCTC?: string;
    //         } = {};
    //         if (!salaryTemplateId) {
    //             errors.salaryTemplateId = 'Required';
    //         }
    //         if (!annualCTC) {
    //             errors.annualCTC = 'Required';
    //         }
    //         return errors;
    //     },
    //     onSubmit: () => { addStaffSalaryDetailsSubmit() },
    // });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    const selectRelievingType = (e: any) => {
        exitOrResignForm.resetForm();
        setRelievingTypeId('')
        setRelievingTypeIdValue('')
        let relievingTypeId = e
        setRelievingTypeId(relievingTypeId?.value)
        setRelievingTypeIdValue(relievingTypeId)
    }

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function viewStaffList() {
        setDataSuccess(false)
        setIsLoader(true);
        columnVisibilityForm.resetForm();
        getStaffListByUserType(staffTypeId?.value)
    }

    const selectUserType = (e: any) => {
        setDataSuccess(false)
        setIsOpenListCard(true)
        let staffTypeId = e
        setStaffTypeId(staffTypeId)
    }

    function onActivate(i: any) {
        if (i != undefined) {

            let selectedStaff = i
            setStaffDetailsId(selectedStaff.staffDetailsId)
            getStaffProfile(selectedStaff.staffDetailsId)
        }
    }

    function getStaffListByUserType(staffTypeId: any) {
        getStaffListByUserTypeId(staffTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffListByUserTypeId;
                    if (data != undefined) {
                        getColumnsForTable('getStaffListByUserTypeId', 'get');
                        setStaffDetailsData(data);
                        setIsLoader(false);
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setDataSuccess(false);
                        setIsOpenListCard(true);
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataSuccess(true);
                    getColumnsForTable('getStaffListByUserTypeId', 'get');
                    setStaffDetailsData([]);
                    setIsOpenListCard(false);
                    setIsLoader(false);
                    setNoDataMsg(response.data.message)
                    //toasts(response.data.message, "Error")
                } else {
                    setDataSuccess(false);
                    setIsOpenListCard(true);
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    function getUserTypeList() {
        getUserType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.userType;
                    if (data != undefined) {
                        const userTypeData = [{ userTypeId: 0, userType: 'Select All' }];
                        data.forEach((obj: { userTypeId: number; userType: string }) => {
                            let userType = userTypeData.push(obj);
                        });
                        setAllUserTypeData(userTypeData);
                        setUserTypeData(data);

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setAllUserTypeData([]);
                    setUserTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [roleTypeId, setRoleTypeId] = useState<any>('')

    const selectRoleChange = (e: any) => {
        setDesignationShow(false);
        setStaffDesignationId('')
        setDepartmentId('')
        let roleTypeId = e
        setRoleTypeId(roleTypeId)
        if (roleTypeId?.value != undefined) {
            getStaffDesignationDetails(roleTypeId?.value)
        }
    }

    function getStaffProfile(staffDetailsId: number) {
        getProfileForStaff(staffDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.profileForStaff[0];
                    let profileData = response.data.data.profileForStaff;
                    let staffPfDetails = data.staffPfDetails;
                    let staffSalaryDetails = data.staffSalaryDetails;
                    if (data != undefined) {
                        //setRoleTypeData(data);
                        setStaffProfileData(profileData)
                        setRoleTypeId({ value: data.userTypeId, label: data.userType })
                        setStaffDesignationId({ value: data.staffDesignationId, label: data.designationName })
                        setDepartmentId({ value: data.departmentId, label: data.departmentName })


                        // setStaffSalaryDetailsShowCanvas(staffSalaryDetails != "" ? false : true)
                        if (data.staffDesignationId != 0) {
                            getStaffDesignationDetails(data.userTypeId)
                        }
                        updateRoleForm.setValues({
                            staffTypeId: data['userTypeId'],
                            staffDesignationId: data['staffDesignationId'],
                            departmentId: data['departmentId']
                        })
                        setStaffPfDetailsData(staffPfDetails)
                        if (staffPfDetails != '') {

                            setStatusId({ value: staffPfDetails['statusId'], label: staffPfDetails['status'] })
                            setIsPfContribution(staffPfDetails['isPfContribution'])

                            updateStaffPfForm.setValues({
                                epfNumber: staffPfDetails['epfNumber'],
                                basicSalary: staffPfDetails['basicSalary'],
                                employeeContribution: staffPfDetails['employeeContribution'],
                                employerContribution: staffPfDetails['employerContribution'],
                                adminCharges: staffPfDetails['adminCharges'],
                                totalPfAmount: staffPfDetails['totalPfAmount'],
                                remarks: ''
                            })
                        } else {
                            setStatusId('')
                        }

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "warning")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStaffDesignationDetails(staffTypeId: any) {
        getStaffDesignation(staffTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffDesignation;
                    if (data != undefined) {
                        setStaffDesignationData(data);
                        setDesignationShow(true);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStaffDesignationData([]);
                    setDesignationShow(false);
                } else {

                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.departmentList;
                    if (data != undefined) {
                        setDepartmentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setDepartmentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setStaffRole() {
        return {
            staffDetailsId: staffDetailsId,
            userTypeId: roleTypeId?.value != undefined ? roleTypeId?.value : 0,
            staffDesignationId: staffDesignationId?.value != undefined ? staffDesignationId?.value : 0,
            departmentId: departmentId?.value != undefined ? departmentId?.value : 0,
            userAccountId: userAccountId,
            licenseKey: getLicenseKey
        }
    }

    function addStaffRoleSubmit() {
        showLoader(true);
        if (updateRoleForm.isValid) {
            let addStaffRolePostData = setStaffRole();
            updateStaffRole(addStaffRolePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        if (isPayrollConnected) {
                            updateStaffRoleForCampus(addStaffRolePostData,
                                (response) => {
                                    const data1 = response.data;
                                    if (data1.success == true) {
                                        showLoader(false);
                                        setAlertStatus({ message: data.message, type: "success" });
                                        setIsOpen(true);
                                        getStaffListByUserType(staffTypeId?.value)
                                        closeAndReset();
                                    } else if (data1.success == false) {
                                        showLoader(false);
                                        setAlertStatus({ message: data1.message, type: "warning" });
                                        setIsOpen(true);
                                    }
                                    else {
                                        showLoader(false);
                                        let errorCode = response.data.error[0].error_code;
                                        let errorDescription = response.data.error[0].error_description;
                                        setAlertStatus({ message: errorDescription, type: "error" });
                                        setIsOpen(true);
                                    }
                                }
                                , (error) => {
                                    showLoader(false);
                                    setAlertStatus({ message: error, type: "error" });
                                    setIsOpen(true);
                                }
                            )

                        } else {
                            showLoader(false);
                            setAlertStatus({ message: data.message, type: "success" });
                            setIsOpen(true);
                            getStaffListByUserType(staffTypeId?.value)
                            closeAndReset();
                        }
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "warning" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (updateRoleForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "warning" });
            setIsOpen(true);
        }

    }

    function setStaffResigningDetails() {
        return {
            staffDetailsId: staffDetailsId,
            resignedDate: exitOrResignForm.values.resignedDate ? exitOrResignForm.values.resignedDate : null,
            remarks: exitOrResignForm.values.remarks ? exitOrResignForm.values.remarks : null,
            terminationDate: exitOrResignForm.values.terminationDate ? exitOrResignForm.values.terminationDate : null,
            terminationReason: exitOrResignForm.values.terminationReason ? exitOrResignForm.values.terminationReason : null,
            userAccountId: userAccountId,
            licenseKey: getLicenseKey
        }
    }

    function exitOrResignSubmit() {
        showLoader(true);
        if (exitOrResignForm.isValid) {
            let exitOrResignPostData = setStaffResigningDetails();
            updateStaffResigningDetails(exitOrResignPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        if (isPayrollConnected) {
                            updateStaffResigningDetailsForCampus(exitOrResignPostData,
                                (response) => {
                                    const data1 = response.data;
                                    if (data1.success == true) {
                                        showLoader(false)
                                        setAlertStatus({ message: data.message, type: "success" });
                                        setIsOpen(true);
                                        getStaffListByUserType(0)
                                        closeAndReset();
                                    } else if (data1.success == false) {
                                        showLoader(false);
                                        setAlertStatus({ message: data1.message, type: "warning" });
                                        setIsOpen(true);
                                    }
                                    else {
                                        showLoader(false);
                                        let errorCode = response.data.error[0].error_code;
                                        let errorDescription = response.data.error[0].error_description;
                                        setAlertStatus({ message: errorDescription, type: "error" });
                                        setIsOpen(true);
                                    }
                                }
                                , (error) => {
                                    showLoader(false);
                                    setAlertStatus({ message: error, type: "error" });
                                    setIsOpen(true);
                                }
                            )

                        } else {
                            showLoader(false)
                            setAlertStatus({ message: data.message, type: "success" });
                            setIsOpen(true);
                            getStaffListByUserType(0)
                            closeAndReset();
                        }
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "warning" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (exitOrResignForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "warning" });
            setIsOpen(true);
        }
    }

    function setStaffDelete() {
        return {
            staffDetailsId: staffDetailsId,
            remarks: deleteStaffListForm.values.remarks,
            userAccountId: userAccountId,
            licenseKey: getLicenseKey
        }
    }

    function staffDeleteSubmit() {
        showLoader(true);
        if (deleteStaffListForm.isValid) {
            let staffDeletePostData = setStaffDelete();
            deleteStaffDetails(staffDeletePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        if (isPayrollConnected) {
                            deleteStaffDetailsForCampus(staffDeletePostData,
                                (response) => {
                                    const data1 = response.data;
                                    if (data1.success == true) {
                                        showLoader(false)
                                        setAlertStatus({ message: data.message, type: "success" });
                                        setIsOpen(true);
                                        setIsOpenListCard(true);
                                        setDataSuccess(false)
                                        closeAndReset();
                                    } else if (data1.success == false) {
                                        showLoader(false);
                                        setAlertStatus({ message: data1.message, type: "warning" });
                                        setIsOpen(true);
                                    }
                                    else {
                                        showLoader(false);
                                        let errorCode = response.data.error[0].error_code;
                                        let errorDescription = response.data.error[0].error_description;
                                        setAlertStatus({ message: errorDescription, type: "error" });
                                        setIsOpen(true);
                                    }
                                }
                                , (error) => {
                                    showLoader(false);
                                    setAlertStatus({ message: error, type: "error" });
                                    setIsOpen(true);
                                }
                            )

                        } else {
                            showLoader(false)
                            setAlertStatus({ message: data.message, type: "success" });
                            setIsOpen(true);
                            setIsOpenListCard(true);
                            setDataSuccess(false)
                            closeAndReset();
                        }
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "warning" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (deleteStaffListForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "warning" });
            setIsOpen(true);
        }
    }

    function setStaffPfDetails() {
        return {
            staffDetailsId: staffDetailsId,
            epfNumber: updateStaffPfForm.values.epfNumber,
            basicSalary: updateStaffPfForm.values.basicSalary,
            employeeContribution: updateStaffPfForm.values.employeeContribution,
            employerContribution: updateStaffPfForm.values.employerContribution,
            adminCharges: updateStaffPfForm.values.adminCharges,
            totalPfAmount: Number(updateStaffPfForm.values.employeeContribution) + Number(updateStaffPfForm.values.employerContribution) + Number(updateStaffPfForm.values.adminCharges),
            remarks: updateStaffPfForm.values.remarks,
            status: statusId?.value,
            pfContribution: statusId?.value == 2 || 4 ? 0 : 1,
            updatedBy: userAccountId,
            licenseKey: getLicenseKey
        }
    }

    function updateStaffPfSubmit() {
        showLoader(true)
        if (updateStaffPfForm.isValid) {
            let pfPostData = setStaffPfDetails();
            updateStaffPfDetails(pfPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        // setIsOpenListCard(true);
                        // setDataSuccess(false)
                        updateStaffPfForm.resetForm();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "warning" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (updateStaffPfForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "warning" });
            setIsOpen(true);
        }
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "warning")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getStaffListByUserTypeId', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "warning")
        }
    }

    // Export Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function closeAndReset() {
        setUpdateRoleCanvas(false)
        // setAddStaffSalaryCanvas(false)
        setExitOrResignCanvas(false)
        setDeleteStaffListCanvas(false)
        exitOrResignForm.resetForm();
        updateRoleForm.resetForm();
        deleteStaffListForm.resetForm();
        updateStaffPfForm.resetForm();
        setRoleTypeId('')
        setRelievingTypeId('')
        setRelievingTypeIdValue('')
        setIsDeleteOpen(false);
        setStaffDesignationData([]);
        setDesignationShow(false)
        setStaffDesignationId('')
        setDepartmentId('')
        setRoleTypeId('')
    }

    function resetForNew() {
        exitOrResignForm.resetForm();
        updateStaffPfForm.resetForm();
        setRelievingTypeId('')
        setRelievingTypeIdValue('')
        setStaffDesignationData([]);
        setStaffPfDetailsData([]);
        setDesignationShow(false)
        setStaffDesignationId('')
        setDepartmentId('')
        setRoleTypeId('')
        setStatusId('')
        setIsPfContribution(false)
    }

    const relievingTypeData = [{ relievingTypeId: 1, relievingType: 'Resign' }, { relievingTypeId: 2, relievingType: 'Termination' }]

    const staffPFStatusData = [{ statusId: 1, status: 'Pf Contribution' }, { statusId: 2, status: 'Resign' }, { statusId: 3, status: 'Update' }, { statusId: 4, status: 'Break-in' }]

    // const [salaryTemplateListById, setSalaryTemplateListById] = useState<any>([])
    // const [salaryTemplateData, setSalaryTemplateData] = useState<any>([]);
    // const [staffSalaryDetails, setStaffSalaryDetails] = useState<any>([]);
    // const [dataStatus, setDataStatus] = useState(false);
    // const [totalMonthlyAmount, setTotalMonthlyAmount] = useState<any>(0)
    // const [totalAnnualAmount, setTotalAnnualAmount] = useState<any>(0)

    // function openAddStaffSalaryCanvas() {
    //     getSalaryTemplate(organisationDetailsId, 0);
    //     setAddStaffSalaryCanvas(true);
    //     resetForNew();
    // }

    // function getSalaryTemplate(organisationDetailsId: any, salaryTemplateId: any) {
    //     getSalaryTemplateList(organisationDetailsId, salaryTemplateId,
    //         (response) => {
    //             if (response.data.success) {
    //                 let data = response.data.data.salaryTemplateList;
    //                 if (data != undefined) {
    //                     setSalaryTemplateData(data);
    //                 } else {
    //                     toasts("Undefined Data", "Error")
    //                 }
    //             }
    //             else if (response.data.success === false) {
    //                 setSalaryTemplateData([]);
    //             } else {
    //                 let errorCode = response.data.error[0].error_code;
    //                 let errorDescription = response.data.error[0].error_description;
    //                 toasts(errorDescription, "Error")
    //             }
    //         }, error => {
    //             toasts(error, "Error")
    //         }
    //     )
    // }

    // function selectSalaryTemplate(salaryTemplateId: any) {
    //     setStaffSalaryDetails([])
    //     setDataStatus(false)
    //     setSalaryTemplateId(salaryTemplateId);
    //     if (salaryTemplateId?.value != undefined) {
    //         let salaryTemplate = salaryTemplateData.filter(
    //             (item: any) => item.salaryTemplateId == salaryTemplateId,
    //         );
    //         setSalaryTemplateListById(salaryTemplate[0]);
    //     }
    // }

    // function multiply(annualCTC: any) {
    //     setAnnualCTC(Number(annualCTC))
    // }

    // function getStaffSalaryList(salaryTemplateId: any, annualCTC: any) {
    //     setStaffSalaryDetails([])
    //     setDataStatus(true)
    //     getStaffSalaryDetails(salaryTemplateId, annualCTC, 0,
    //         (response) => {
    //             if (response.data.success) {
    //                 let data = response.data.data.staffSalaryDetails;
    //                 if (data != undefined) {
    //                     setStaffSalaryDetails(data)
    //                     let monthlyAmount = data.map((item: any) => item.monthlyAmount)
    //                     let annualAmount = data.map((item: any) => item.annualAmount)
    //                     let totalMonthlyAmount = (monthlyAmount.reduce((a: any, v: any) => a = a + v, 0))
    //                     let totalAnnualAmount = (annualAmount.reduce((a: any, v: any) => a = a + v, 0))
    //                     setTotalMonthlyAmount(totalMonthlyAmount.toFixed(2));
    //                     setTotalAnnualAmount(totalAnnualAmount.toFixed(2));
    //                     setDataStatus(false)
    //                 } else {
    //                     toasts("Undefined Data", "Error")
    //                 }
    //             }
    //             else if (response.data.success === false) {
    //                 // toasts(response.data.message, "Error")
    //                 setStaffSalaryDetails([])
    //                 setDataStatus(false)
    //             } else {
    //                 let errorCode = response.data.error[0].error_code;
    //                 let errorDescription = response.data.error[0].error_description;
    //                 toasts(errorDescription, "Error")
    //                 setDataStatus(false)
    //             }
    //         }, error => {
    //             toasts(error, "Error")
    //             setDataStatus(false)
    //         }
    //     )
    // }

    // function setAddStaffSalaryDetails() {
    //     return {
    //         staffDetailsId: staffDetailsId,
    //         annualCTC: annualCTC,
    //         salaryTemplateId: salaryTemplateId,
    //         updatedBy: userAccountId,
    //         licenseKey: getLicenseKey
    //     }
    // }

    // function addStaffSalaryDetailsSubmit() {
    //     showLoader(true)
    //     if (salaryInfoForm.isValid) {
    //         let salaryDetailsPostData = setAddStaffSalaryDetails();
    //         addStaffSalaryDetails(salaryDetailsPostData,
    //             (response) => {
    //                 const data = response.data;
    //                 if (data.success == true) {
    //                     showLoader(false)
    //                     setAlertStatus({ message: data.message, type: "success" });
    //                     setIsOpen(true);
    //                     closeAndReset();
    //                 }
    //                 else if (data.success == false) {
    //                     showLoader(false)
    //                     setAlertStatus({ message: data.message, type: "warning" });
    //                     setIsOpen(true);
    //                 }
    //                 else {
    //                     showLoader(false)
    //                     let errorCode = response.data.error[0].error_code;
    //                     let errorDescription = response.data.error[0].error_description;
    //                     setAlertStatus({ message: errorDescription, type: "error" });
    //                     setIsOpen(true);
    //                 }
    //             }
    //             , (error) => {
    //                 showLoader(false)
    //                 setAlertStatus({ message: error, type: "error" });
    //                 setIsOpen(true);
    //             }
    //         )
    //     } else if (salaryInfoForm.isValid == false) {
    //         showLoader(false)
    //         setAlertStatus({ message: 'Please fill all the details!', type: "warning" });
    //         setIsOpen(true);
    //     }
    // }

    return (

        <PageWrapper title='Staff List'>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card stretch data-tour='list' className='d-print-none'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle>
                                    Staff List
                                </CardTitle>
                            </CardLabel>
                            <CardActions className='float-end'>
                                <Button
                                    icon='Add'
                                    color='primary'
                                    isLight
                                    tag='a'
                                    to={`../userManagement/addStaff`}>
                                    Add New
                                </Button>
                            </CardActions>
                        </CardHeader>
                        <CardBody>
                            <div className='row g-4'>
                                <div className='col-3'>
                                    <FormGroup id='staffTypeId' label='User Type' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Type'
                                            onChange={selectUserType}
                                            value={staffTypeId}
                                            list={allUserTypeData.map((data: any) => (
                                                { value: data.userTypeId, label: data.userType }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-3'>
                                    <Button className="mt-2" icon='ArrowDownward' isDisable={staffTypeId?.value == undefined} color='primary' onClick={viewStaffList}>View</Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                {dataSuccess ?
                    <Card stretch data-tour='list' ref={componentRef}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-6">
                                <CardTitle tag='div' className='h5'>Staff List</CardTitle>
                            </CardLabel>&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getStaffListByUserTypeId', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("StaffList", staffDetailsData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(staffDetailsData, columnVisibilityData, "StaffList")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("StaffList", staffDetailsData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>

                            </CardActions>
                        </CardHeader>

                        <CardBody className='table-responsive' isScrollable>
                            <table className='table table-modern table-hover text-nowrap'>
                                <thead>
                                    <tr>
                                        <th scope='col' onClick={() => requestSort('sno')}
                                            className='cursor-pointer text-decoration-underline'>S.No{''}
                                            <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                        </th>
                                        {columnVisibilityData.map((column: any) => (
                                            <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                className='cursor-pointer text-decoration-underline'>
                                                {column.columnName}
                                                <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                            </th>
                                        ))}
                                        <td className="d-print-none" />
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData != "" ? <>{filteredData.map((rowData: any) => (<tr key={rowData.staffDetailsId}><td>{rowData.sno}</td>{columnVisibilityData.map((column: any) => ((column.isDisplay && rowData[column.keyName]) ? <td className='text-nowrap' key={`${rowData.staffDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}<td className='d-print-none' onClick={() => { onActivate(rowData) }} ><Dropdown isButtonGroup><DropdownToggle><Button className='text-nowrap' color='dark' isLight icon='MoreHoriz' aria-label='MoreHoriz'>More</Button></DropdownToggle><DropdownMenu isAlignmentEnd className='card-body-scrollable' size='lg'><DropdownItem><Button icon="Replay" isLight color="info" onClick={() => navigate(`../userManagement/staffProfile/${staffDetailsId}`)}>Staff Profile</Button></DropdownItem>
                                        {/* <DropdownItem><Button icon="Receipt" isLight color="success" onClick={() => navigate(`../userManagement/updateStaff/${staffDetailsId}`)}>Update staff Details</Button></DropdownItem><DropdownItem><Button icon="Save" isLight color="primary" onClick={() => { setUpdateRoleCanvas(true); resetForNew(); }}>Update Role</Button></DropdownItem>{staffSalaryDetailsShowCanvas ? <DropdownItem><Button icon="Save" isLight color="primary" onClick={() => { openAddStaffSalaryCanvas() }}>Add Staff Salary</Button></DropdownItem> : null} */}
                                        {/* <DropdownItem>
                                            <Button icon="Money" isLight color="primary" onClick={() => window.open(`../salary/payslip/${getCurrentDateAndTime('date').slice(0, 7)}/${staffDetailsId}`)}>Salary Details</Button></DropdownItem> */}
                                        <DropdownItem>
                                            <Button icon="AdminPanelSettings" isLight color="primary" onClick={() => { setStaffPfDetailsCanvas(true) }}>Staff Pf Details</Button></DropdownItem><DropdownItem>
                                            <Button icon="ExitToApp" isLight color="primary" onClick={() => { setExitOrResignCanvas(true); resetForNew(); }}>Exit or Resign</Button></DropdownItem><DropdownItem>
                                            <Button icon="Delete" isLight color="danger" onClick={() => { setDeleteStaffListCanvas(true) }}>Delete</Button></DropdownItem>
                                    </DropdownMenu></Dropdown></td></tr>))}</> : <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}</tbody></table>
                        </CardBody>

                        <PaginationButtons
                            className='d-print-none'
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        />
                    </Card>
                    : null}
            </Page>

            <OffCanvas
                setOpen={setUpdateRoleCanvas}
                isOpen={updateRoleCanvas}
                titleId='updateRoleCanvas'
                isBodyScroll
                placement='end' tag='form' noValidate onSubmit={updateRoleForm.handleSubmit}>
                <OffCanvasHeader setOpen={setUpdateRoleCanvas} onClick={closeAndReset}>
                    <OffCanvasTitle id="updateRoleCanvas">Update Role</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'>
                        {staffProfileData.map((staff: any) => (
                            <div className='col-12' key={staff.staffUserAccountId}>
                                <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                    }-info bg-l${darkModeStatus ? 'o50' : '10'
                                    }-info-hover transition-base rounded-2 mb-4`}
                                    shadow='sm'>
                                    <CardBody>
                                        <div className='d-flex align-items-center pb-3'>
                                            <div className='flex-shrink-0 ms-2'>
                                                <img style={{ borderRadius: '50%' }} src={staff.profilePath != null ? staff.profilePath : profilePic(staff.genderId)} height={65} width={65} onError={(e: any) => pictNotLoading(e, staff.genderId)} />
                                            </div>
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-4 text-dark mb-0'>
                                                    {staff.fullname}
                                                </div>
                                                <div className='text-muted'>
                                                    ( {staff.empNumber} )
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        ))}
                    </div>
                    <div className="row g-4">
                        <div className="col-lg-12">
                            <FormGroup id="staffTypeId" label="Role">
                                <SearchableSelect ariaLabel="" placeholder="Select Role Type"
                                    onChange={selectRoleChange}
                                    onBlur={updateRoleForm.handleBlur}
                                    value={roleTypeId}
                                    list={userTypeData.map((data: any) => (
                                        { value: data.userTypeId, label: data.userType }
                                    ))} />
                            </FormGroup>
                        </div>
                        {designationShow == true ?
                            <div className="col-lg-12">
                                <FormGroup id="staffDesignationId" label="Designation">
                                    <SearchableSelect ariaLabel="" placeholder="Select Designation"
                                        onChange={(e: any) => setStaffDesignationId(e)}
                                        onBlur={updateRoleForm.handleBlur}
                                        value={staffDesignationId}
                                        list={staffDesignationData.map((data: any) => (
                                            { value: data.staffDesignationId, label: data.designationName }
                                        ))}
                                        isValid={updateRoleForm.isValid}
                                        isTouched={updateRoleForm.touched.staffDesignationId}
                                        invalidFeedback={updateRoleForm.errors.staffDesignationId} />
                                </FormGroup>
                            </div> : null}

                        {roleTypeId?.value == 7 ?
                            <div className="col-lg-12">
                                <FormGroup id="departmentId" label="Department">
                                    <SearchableSelect ariaLabel="" placeholder="Select Department"
                                        onChange={(e: any) => setDepartmentId(e)}
                                        value={departmentId}
                                        list={departmentData.map((data: any) => (
                                            { value: data.departmentId, label: data.departmentName }
                                        ))} isValid={updateRoleForm.isValid}
                                        isTouched={updateRoleForm.touched.departmentId}
                                        invalidFeedback={updateRoleForm.errors.departmentId}
                                    />
                                </FormGroup>
                            </div> : null}

                    </div>

                </OffCanvasBody>
                <div className='row m-0'>
                    <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                        <Button
                            color='info'
                            className='w-100' type="submit">
                            Save
                        </Button>
                    </div>
                </div>
            </OffCanvas>

            <OffCanvas
                setOpen={setExitOrResignCanvas}
                isOpen={exitOrResignCanvas}
                titleId='exitOrResignCanvas'
                isBodyScroll
                placement='end' tag='form' noValidate onSubmit={exitOrResignForm.handleSubmit}>
                <OffCanvasHeader setOpen={setExitOrResignCanvas} onClick={closeAndReset}>
                    <OffCanvasTitle id="exitOrResignCanvas">Exit / Resign</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'>
                        {staffProfileData.map((staff: any) => (
                            <div className='col-12' key={staff.staffUserAccountId}>
                                <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                    }-info bg-l${darkModeStatus ? 'o50' : '10'
                                    }-info-hover transition-base rounded-2 mb-4`}
                                    shadow='sm'>
                                    <CardBody>
                                        <div className='d-flex align-items-center pb-3'>
                                            <div className='flex-shrink-0 ms-2'>
                                                <img style={{ borderRadius: '50%' }} src={staff.profilePath != null ? staff.profilePath : profilePic(staff.genderId)} height={65} width={65} onError={(e: any) => pictNotLoading(e, staff.genderId)} />
                                            </div>
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-4 text-dark mb-0'>
                                                    {staff.fullname}
                                                </div>
                                                <div className='text-muted'>
                                                    ( {staff.empNumber} )
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        ))}
                        <div className='col-lg-12'>
                            <FormGroup id="relievingTypeId" label="Select Relieving Type" >
                                <SearchableSelect placeholder="Select Relieving Type"
                                    ariaLabel='Relieving Type'
                                    onChange={selectRelievingType}
                                    value={relievingTypeIdValue}
                                    list={relievingTypeData.map((data: any) => (
                                        { value: data.relievingTypeId, label: data.relievingType }
                                    ))}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row g-4 mt-2">
                        {relievingTypeId == '1' ?
                            <>
                                <div className="col-lg-12">
                                    <FormGroup id='resignedDate' label='Resigned Date'>
                                        <Input type='date'
                                            onChange={exitOrResignForm.handleChange}
                                            value={exitOrResignForm.values.resignedDate}
                                            onBlur={exitOrResignForm.handleBlur}
                                            isValid={exitOrResignForm.isValid}
                                            isTouched={exitOrResignForm.touched.resignedDate}
                                            invalidFeedback={exitOrResignForm.errors.resignedDate} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-12">
                                    <FormGroup id='remarks' label='Remarks'>
                                        <Textarea
                                            placeholder="Enter Remarks"
                                            onChange={exitOrResignForm.handleChange}
                                            value={exitOrResignForm.values.remarks} />
                                    </FormGroup>
                                </div>
                            </> : null}
                        {relievingTypeId == '2' ?
                            <>
                                <div className="col-lg-12">
                                    <FormGroup id='terminationDate' label='Termination Date'>
                                        <Input type='date'
                                            onChange={exitOrResignForm.handleChange}
                                            value={exitOrResignForm.values.terminationDate} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-12">
                                    <FormGroup id='terminationReason' label='Termination Reason'>
                                        <Textarea
                                            placeholder="Enter Termination Reason"
                                            onChange={exitOrResignForm.handleChange}
                                            value={exitOrResignForm.values.terminationReason} />
                                    </FormGroup>
                                </div>
                            </> : null}
                    </div>

                </OffCanvasBody>
                <div className='row m-0'>
                    <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                        <Button
                            color='info'
                            className='w-100' type="submit" isDisable={!exitOrResignForm.isValid && !!exitOrResignForm.submitCount}>
                            Save
                        </Button>
                    </div>
                </div>
            </OffCanvas>

            <OffCanvas
                setOpen={setDeleteStaffListCanvas}
                isOpen={deleteStaffListCanvas}
                titleId='deleteStaffListCanvas'
                isBodyScroll
                placement='end'
                tag="form" noValidate onSubmit={deleteStaffListForm.handleSubmit}>
                <OffCanvasHeader setOpen={setDeleteStaffListCanvas} onClick={closeAndReset}>
                    <OffCanvasTitle id="deleteStaffListCanvas">Delete Staff</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row">
                        {staffProfileData.map((staff: any) => (
                            <div className='col-12' key={staff.staffUserAccountId}>
                                <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                    }-info bg-l${darkModeStatus ? 'o50' : '10'
                                    }-info-hover transition-base rounded-2 mb-4`}
                                    shadow='sm'>
                                    <CardBody>
                                        <div className='d-flex align-items-center pb-3'>
                                            <div className='flex-shrink-0 ms-2'>
                                                <img style={{ borderRadius: '50%' }} src={staff.profilePath != null ? staff.profilePath : profilePic(staff.genderId)} height={65} width={65} onError={(e: any) => pictNotLoading(e, staff.genderId)} />
                                            </div>
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-4 text-dark mb-0'>
                                                    {staff.fullname}
                                                </div>
                                                <div className='text-muted'>
                                                    ( {staff.empNumber} )
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        ))}
                        <div className="col-12 mt-3">
                            <FormGroup id='remarks' label="Remarks">
                                <Textarea
                                    placeholder="Enter Remarks"
                                    onChange={deleteStaffListForm.handleChange}
                                    value={deleteStaffListForm.values.remarks} />
                            </FormGroup>
                        </div>

                        <div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
                            <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={staffDeleteSubmit} />
                        </div>
                    </div>
                </OffCanvasBody>
                <div className='row m-0'>
                    <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                        <Button
                            color='danger'
                            icon='Delete'
                            className='w-100' type="submit" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                            Delete
                        </Button>
                    </div>
                </div>
            </OffCanvas>

            <OffCanvas
                setOpen={setStaffPfDetailsCanvas}
                isOpen={staffPfDetailsCanvas}
                titleId='staffPfDetailsCanvas'
                isBodyScroll
                placement='end' tag='form' noValidate onSubmit={updateStaffPfForm.handleSubmit}>
                <OffCanvasHeader setOpen={setStaffPfDetailsCanvas} onClick={closeAndReset}>
                    <OffCanvasTitle id="staffPfDetailsCanvas">Update Pf Details</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row'>
                        {staffProfileData.map((staff: any) => (
                            <div className='col-12' key={staff.staffUserAccountId}>
                                <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                    }-info bg-l${darkModeStatus ? 'o50' : '10'
                                    }-info-hover transition-base rounded-2 mb-4`}
                                    shadow='sm'>
                                    <CardBody>
                                        <div className='d-flex align-items-center pb-3'>
                                            <div className='flex-shrink-0 ms-2'>
                                                <img style={{ borderRadius: '50%' }} src={staff.profilePath != null ? staff.profilePath : profilePic(staff.genderId)} height={65} width={65} onError={(e: any) => pictNotLoading(e, staff.genderId)} />
                                            </div>
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-5 text-dark mb-0'>
                                                    {staff.fullname}
                                                </div>
                                                <div className='text-muted'>
                                                    ( {staff.empNumber} )
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        ))}
                    </div>

                    <div className="row g-4">
                        <div className='col-12'>
                            <FormGroup id="statusId" label="Select Status" >
                                <SearchableSelect placeholder="Select Status"
                                    ariaLabel='Select Status'
                                    onChange={(e: any) => setStatusId(e)}
                                    value={statusId}
                                    list={staffPFStatusData.map((data: any) => (
                                        { value: data.statusId, label: data.status }
                                    ))}
                                />
                            </FormGroup>
                        </div>

                        {statusId?.value == 1 ?
                            <>
                                <div className='col-12'>
                                    <FormGroup id='epfNumber' label='EPF Number'>
                                        <Input
                                            placeholder='EPF Number'
                                            onChange={updateStaffPfForm.handleChange}
                                            onBlur={updateStaffPfForm.handleBlur}
                                            value={updateStaffPfForm.values.epfNumber}
                                            isValid={updateStaffPfForm.isValid}
                                            isTouched={updateStaffPfForm.touched.epfNumber}
                                            invalidFeedback={updateStaffPfForm.errors.epfNumber}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id='basicSalary' label='Basic Salary'>
                                        <Input
                                            placeholder='0'
                                            onChange={updateStaffPfForm.handleChange}
                                            onBlur={updateStaffPfForm.handleBlur}
                                            value={updateStaffPfForm.values.basicSalary}
                                            isValid={updateStaffPfForm.isValid}
                                            isTouched={updateStaffPfForm.touched.basicSalary}
                                            invalidFeedback={updateStaffPfForm.errors.basicSalary}
                                        />
                                    </FormGroup>
                                </div>

                                <div className='col-6'>
                                    <FormGroup id='employeeContribution' label='Employee Contribution'>
                                        <Input
                                            placeholder='0'
                                            onChange={updateStaffPfForm.handleChange}
                                            onBlur={updateStaffPfForm.handleBlur}
                                            value={updateStaffPfForm.values.employeeContribution}
                                            isValid={updateStaffPfForm.isValid}
                                            isTouched={updateStaffPfForm.touched.employeeContribution}
                                            invalidFeedback={updateStaffPfForm.errors.employeeContribution}
                                        />
                                    </FormGroup>
                                </div>

                                <div className='col-6'>
                                    <FormGroup id='employerContribution' label='Employer Contribution'>
                                        <Input
                                            placeholder=''
                                            onChange={updateStaffPfForm.handleChange}
                                            onBlur={updateStaffPfForm.handleBlur}
                                            value={updateStaffPfForm.values.employerContribution}
                                            isValid={updateStaffPfForm.isValid}
                                            isTouched={updateStaffPfForm.touched.employerContribution}
                                            invalidFeedback={updateStaffPfForm.errors.employerContribution}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-6'>
                                    <FormGroup id='adminCharges' label='Admin Charges'>
                                        <Input
                                            placeholder='0'
                                            onChange={updateStaffPfForm.handleChange}
                                            onBlur={updateStaffPfForm.handleBlur}
                                            value={updateStaffPfForm.values.adminCharges}
                                            isValid={updateStaffPfForm.isValid}
                                            isTouched={updateStaffPfForm.touched.adminCharges}
                                            invalidFeedback={updateStaffPfForm.errors.adminCharges}
                                        />
                                    </FormGroup>
                                </div>

                                <div className='col-6'>
                                    <FormGroup id='totalPfAmount' label='Total Pf Amount'>
                                        <Input disabled
                                            placeholder='0'
                                            onChange={updateStaffPfForm.handleChange}
                                            onBlur={updateStaffPfForm.handleBlur}
                                            value={Number(updateStaffPfForm.values.employeeContribution) + Number(updateStaffPfForm.values.employerContribution) + Number(updateStaffPfForm.values.adminCharges)}
                                        // isValid={updateStaffPfForm.isValid}
                                        // isTouched={updateStaffPfForm.touched.totalPfAmount}
                                        // invalidFeedback={updateStaffPfForm.errors.totalPfAmount}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-12"><br /><br /><br /></div>
                            </> : statusId?.value == 2 ?
                                <div className="col-lg-12">
                                    <FormGroup id='remarks' label='Remarks'>
                                        <Textarea
                                            placeholder="Enter Remarks"
                                            onChange={updateStaffPfForm.handleChange}
                                            value={updateStaffPfForm.values.remarks}
                                            onBlur={updateStaffPfForm.handleBlur}
                                            isValid={updateStaffPfForm.isValid}
                                            isTouched={updateStaffPfForm.touched.remarks}
                                            invalidFeedback={updateStaffPfForm.errors.remarks} />
                                    </FormGroup>
                                </div>
                                : statusId?.value == 2 ?
                                    <>
                                        <div className='col-12'>
                                            <FormGroup id='epfNumber' label='EPF Number'>
                                                <Input
                                                    placeholder='EPF Number'
                                                    onChange={updateStaffPfForm.handleChange}
                                                    onBlur={updateStaffPfForm.handleBlur}
                                                    value={updateStaffPfForm.values.epfNumber}
                                                    isValid={updateStaffPfForm.isValid}
                                                    isTouched={updateStaffPfForm.touched.epfNumber}
                                                    invalidFeedback={updateStaffPfForm.errors.epfNumber}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-12'>
                                            <FormGroup id='basicSalary' label='Basic Salary'>
                                                <Input
                                                    placeholder='0'
                                                    onChange={updateStaffPfForm.handleChange}
                                                    onBlur={updateStaffPfForm.handleBlur}
                                                    value={updateStaffPfForm.values.basicSalary}
                                                    isValid={updateStaffPfForm.isValid}
                                                    isTouched={updateStaffPfForm.touched.basicSalary}
                                                    invalidFeedback={updateStaffPfForm.errors.basicSalary}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className='col-6'>
                                            <FormGroup id='employeeContribution' label='Employee Contribution'>
                                                <Input
                                                    placeholder='0'
                                                    onChange={updateStaffPfForm.handleChange}
                                                    onBlur={updateStaffPfForm.handleBlur}
                                                    value={updateStaffPfForm.values.employeeContribution}
                                                    isValid={updateStaffPfForm.isValid}
                                                    isTouched={updateStaffPfForm.touched.employeeContribution}
                                                    invalidFeedback={updateStaffPfForm.errors.employeeContribution}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className='col-6'>
                                            <FormGroup id='employerContribution' label='Employer Contribution'>
                                                <Input
                                                    placeholder=''
                                                    onChange={updateStaffPfForm.handleChange}
                                                    onBlur={updateStaffPfForm.handleBlur}
                                                    value={updateStaffPfForm.values.employerContribution}
                                                    isValid={updateStaffPfForm.isValid}
                                                    isTouched={updateStaffPfForm.touched.employerContribution}
                                                    invalidFeedback={updateStaffPfForm.errors.employerContribution}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-6'>
                                            <FormGroup id='adminCharges' label='Admin Charges'>
                                                <Input
                                                    placeholder='0'
                                                    onChange={updateStaffPfForm.handleChange}
                                                    onBlur={updateStaffPfForm.handleBlur}
                                                    value={updateStaffPfForm.values.adminCharges}
                                                    isValid={updateStaffPfForm.isValid}
                                                    isTouched={updateStaffPfForm.touched.adminCharges}
                                                    invalidFeedback={updateStaffPfForm.errors.adminCharges}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className='col-6'>
                                            <FormGroup id='totalPfAmount' label='Total Pf Amount'>
                                                <Input disabled
                                                    placeholder='0'
                                                    onChange={updateStaffPfForm.handleChange}
                                                    onBlur={updateStaffPfForm.handleBlur}
                                                    value={Number(updateStaffPfForm.values.employeeContribution) + Number(updateStaffPfForm.values.employerContribution) + Number(updateStaffPfForm.values.adminCharges)}
                                                // isValid={updateStaffPfForm.isValid}
                                                // isTouched={updateStaffPfForm.touched.totalPfAmount}
                                                // invalidFeedback={updateStaffPfForm.errors.totalPfAmount}
                                                />
                                            </FormGroup>
                                        </div><div className="col-lg-12"><br /><br /><br /></div></>
                                    : statusId?.value == 2 ?
                                        <div className="col-lg-12">
                                            <FormGroup id='remarks' label='Remarks'>
                                                <Textarea
                                                    placeholder="Enter Remarks"
                                                    onChange={updateStaffPfForm.handleChange}
                                                    value={updateStaffPfForm.values.remarks}
                                                    onBlur={updateStaffPfForm.handleBlur}
                                                    isValid={updateStaffPfForm.isValid}
                                                    isTouched={updateStaffPfForm.touched.remarks}
                                                    invalidFeedback={updateStaffPfForm.errors.remarks} />
                                            </FormGroup>
                                        </div> : null}


                    </div>

                </OffCanvasBody>
                <div className='row m-0'>
                    <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                        <Button
                            color='info'
                            className='w-100' icon="Save" type="submit" isDisable={!updateStaffPfForm.isValid && !!updateStaffPfForm.submitCount}>
                            Save
                        </Button>
                    </div>
                </div>
            </OffCanvas>

            {/* <OffCanvas
                setOpen={setAddStaffSalaryCanvas}
                isOpen={addStaffSalaryCanvas}
                titleId='addStaffSalaryCanvas'
                isBodyScroll
                placement='end' tag='form' noValidate onSubmit={updateRoleForm.handleSubmit}>
                <OffCanvasHeader setOpen={setAddStaffSalaryCanvas} onClick={closeAndReset}>
                    <OffCanvasTitle id="addStaffSalaryCanvas">Add Staff Salary</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-2'>
                        {staffProfileData.map((staff: any) => (
                            <div className='col-12' key={staff.staffUserAccountId}>
                                <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                    }-info bg-l${darkModeStatus ? 'o50' : '10'
                                    }-info-hover transition-base rounded-2 mb-4`}
                                    shadow='sm'>
                                    <CardBody>
                                        <div className='d-flex align-items-center pb-3'>
                                            <div className='flex-shrink-0 ms-2'>
                                                <img style={{ borderRadius: '50%' }} src={staff.profilePath != null ? staff.profilePath : profilePic(staff.genderId)} height={60} width={60} onError={(e: any) => pictNotLoading(e, staff.genderId)} />
                                            </div>
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-4 text-dark mb-0'>
                                                    {staff.fullname}
                                                </div>
                                                <div className='text-muted'>
                                                    ( {staff.empNumber} )
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        ))}
                    </div>
                    <div className='row g-4'>
                        <div className='col-12'>
                            <FormGroup
                                id='salaryTemplateId'
                                label='Salary Templates'>
                                <SearchableSelect

                                    ariaLabel=''
                                    placeholder='Select Template'
                                    onChange={(e: any) =>
                                        selectSalaryTemplate(e)
                                    }
                                    value={salaryTemplateId}
                                    onBlur={salaryInfoForm.handleBlur}
                                    isValid={salaryInfoForm.isValid}
                                    invalidFeedback={
                                        salaryInfoForm.errors.salaryTemplateId
                                    }
                                    isTouched={
                                        salaryInfoForm.touched.salaryTemplateId
                                    }
                                    list={salaryTemplateData.map(
                                        (data: any) => ({
                                            value: data.salaryTemplateId,
                                            label: data.templateName,
                                        }),
                                    )}
                                />
                            </FormGroup>
                        </div>
                        <div className='col-6'>
                            <FormGroup
                                id='annualCTC'
                                label='Annual CTC (Per Year)'>
                                <Input
                                    aria-placeholder=''
                                    placeholder='Enter Annual CTC'
                                    onInput={(e: any) =>
                                        multiply(Number(e.target.value))
                                    }
                                    value={annualCTC}
                                    onBlur={salaryInfoForm.handleBlur}
                                    isValid={salaryInfoForm.isValid}
                                    invalidFeedback={
                                        salaryInfoForm.errors.annualCTC
                                    }
                                    isTouched={salaryInfoForm.touched.annualCTC}
                                    onKeyDown={(e: any) => onlyAllowNumber(e)}
                                />
                            </FormGroup>
                        </div>

                        <div className='col-6'>
                            <FormGroup
                            >
                                <Button className="mt-4"
                                    icon='ArrowDownward'
                                    color='primary'
                                    isDisable={salaryTemplateId?.value != undefined && annualCTC > 0 ? false : true}
                                    onClick={() =>
                                        getStaffSalaryList(
                                            salaryTemplateId?.value,
                                            annualCTC,
                                        )
                                    }>
                                    View Salary Details
                                </Button>
                            </FormGroup>
                        </div>

                        {dataStatus ?
                            <div className='col-12 mt-4'>
                                <TableLoader />
                            </div> :

                            <>{staffSalaryDetails != '' ?
                                <div className='col-12 mt-4'>
                                    <table className='table table-modern table-hover text-nowrap'>
                                        <thead>
                                            <tr className='table-success text-decoration-underline mt-4'>
                                                <th>Salary <br /> Components</th>
                                                <th>Monthly <br />Amount</th>
                                                <th>Annual <br />Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {staffSalaryDetails?.map(
                                                (i: any) => (
                                                    <tr
                                                        className='text-start'
                                                        key={i.salaryTemplateId}>
                                                        <td>{i.earningName}  <br /> {i.labelValue}</td>
                                                        <td>{i.monthlyAmount}</td>
                                                        <td>{i.annualAmount}</td>
                                                    </tr>
                                                ),
                                            )}

                                            <tr>
                                                <td >
                                                    <strong>Cost to Company</strong>
                                                </td>
                                                <td className='text-start'>
                                                    <strong className='mt-2 ms-1'>
                                                        ₹ {totalMonthlyAmount}
                                                    </strong>
                                                </td>
                                                <td className='text-start'>
                                                    <strong className='mt-2 ms-1'>
                                                        ₹ {totalAnnualAmount}
                                                    </strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                : null}
                            </>
                        }
                    </div>

                </OffCanvasBody>
                <div className='row m-0'>
                    <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                        <Button
                            color='info'
                            className='w-100' type="submit">
                            Save
                        </Button>
                    </div>
                </div>
            </OffCanvas> */}

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

        </PageWrapper>

    );
}

export default StaffList