import React, { useContext, useEffect, useRef, useState } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import Icon from '../icon/Icon';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../bootstrap/Dropdown';
import FormGroup from '../bootstrap/forms/FormGroup';
import { useReactToPrint } from 'react-to-print';
import Button from '../bootstrap/Button';
import { useFormik } from 'formik';
import Input from '../bootstrap/forms/Input';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../contexts/authContext';
import useSortableData from '../../hooks/useSortableData';
import { dataPagination } from '../PaginationButtons';
import Collapse from '../bootstrap/Collapse';
import OpenCardComponent from '../../common/components/OpenCardComponents';
import { convertNumToMonth, getColumnsForDataTable, getCurrentDateAndTime, getPaymentType, onlyAllowNumber } from '../../services/common.service';
import NoDataMsg from '../../common/components/NoDataMsg';
import { getStaffListForPayRun } from '../../services/salary.service';
import { toasts } from '../../services/toast.service';
import { getLicenseKey } from '../../services/application.settings';
import AlertService from '../../services/AlertService';
import useMinimizeAside from '../../hooks/useMinimizeAside';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../layout/SubHeader/SubHeader';
import { convertJsonToPdf, downloadExcel, downloadFile } from '../../services/export.service';
import '../../common/components/toPrint.css'
import Select from '../bootstrap/forms/Select';
import { getStaffListForPayRunReport } from '../../services/report.service';
import SearchableSelect from '../../common/components/SearchableSelect';
import classNames from 'classnames';
import { priceFormat } from '../../helpers/helpers';
import useDarkMode from '../../hooks/useDarkMode';

const SalaryReport = () => {

    useMinimizeAside();

    useEffect(() => {
        getPaymentTypeList();
    }, []);

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const { items, requestSort, getClassNamesFor } = useSortableData([]);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [isOpenListCard, setIsOpenListCard] = useState(true);
    const [isLoader, setIsLoader] = useState(false);
    const [dataSuccess, setDataSuccess] = useState(false);
    const [salaryDetails, setSalaryDetails] = useState<any>([]);
    const [noDataMsg, setNoDataMsg] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const componentRef = useRef(null);
    const navigate = useNavigate();

    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [columnDataById, setColumnDataById] = useState([]);
    const [paymentTypeData, setPaymentTypeData] = useState<any>([])
    const [paymentTypeId, setPaymentTypeId] = useState<any>({ value: 0, label: 'Select All' })
    const [paymentStatusId, setPaymentStatusId] = useState<any>('')

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [totalSalaryAmount, setTotalSalaryAmount] = useState<any>(0);
    const [totalPaidSalaryAmount, setTotalPaidSalaryAmount] = useState<any>(0);
    const [totalUpPaidSalaryAmount, setTotalUpPaidSalaryAmount] = useState<any>(0);
    const [totalStaffCount, setTotalStaffCount] = useState<any>(0);

    const payRunForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            monthAndYear: '',
            paymentTypeId: '',
            paymentStatusId: '',
        },
        validate: (values) => {
            const errors: {
                monthAndYear?: string;
                paymentTypeId?: string;
                paymentStatusId?: string;
            } = {};

            if (!values.monthAndYear) {
                errors.monthAndYear = 'Required';
            }
            if (!paymentTypeId) {
                errors.paymentTypeId = 'Required';
            }
            if (!paymentStatusId) {
                errors.paymentStatusId = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { viewSalaryDetails() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    function viewSalaryDetails() {
        setDataSuccess(false);
        setIsLoader(true);
        let monthAndYear = payRunForm.values.monthAndYear;
        const splitDate = monthAndYear.split('-');
        const year = splitDate[0];
        const month = splitDate[1];

        setYear(year);
        setMonth(month);
        getStaffSalaryList(year, month, paymentStatusId?.value, paymentStatusId?.value != 4 ? paymentTypeId?.value : 0);
    }

    function getStaffSalaryList(year: any, month: any, paymentTypeId: any, paymentStatusId: any) {
        setIsLoader(true);
        getStaffListForPayRunReport(year, month, paymentTypeId, paymentStatusId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffListForPayRunReport;
                    if (data != undefined) {
                        setSalaryDetails(data);
                        getColumnsForTable('getStaffListForPayRunReport', 'get');
                        setIsLoader(false);
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }

                        setTotalStaffCount(data.length)
                        let totalAmount1 = 0;
                        let totalSalaryAmount1 = 0;
                        let totalPending1 = 0;
                        for (let data1 of data) {
                            let totalSalaryAmount = Number(data1.totalSalaryAmount)
                            let collectionAmount = Number(data1.paidAmount)
                            let pendingAmount = Number(data1.pendingAmount)
                            totalSalaryAmount1 += totalSalaryAmount;
                            totalAmount1 += collectionAmount;
                            totalPending1 += pendingAmount;
                        }
                        setTotalSalaryAmount(totalSalaryAmount1);
                        setTotalPaidSalaryAmount(totalAmount1);
                        setTotalUpPaidSalaryAmount(totalPending1);
                    } else {
                        setIsLoader(false);
                        setDataSuccess(false);
                        setIsOpenListCard(false);
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setIsLoader(false);
                    getColumnsForTable('getStaffListForPayRunReport', 'get');
                    setSalaryDetails([]);
                    setDataSuccess(true);
                    setIsOpenListCard(false);
                    setNoDataMsg(response.data.message);
                }
            },
            (error) => {
                setIsLoader(false);
                toasts(error, 'Error');
            },
        );
    }

    function getPaymentTypeList() {
        getPaymentType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.paymentType;
                    if (data != undefined) {
                        const paymentTypeData = [{ paymentTypeId: 0, paymentType: 'Select All' }];
                        data.forEach((obj: { paymentTypeId: number; paymentType: string }) => {
                            let paymentType = paymentTypeData.push(obj);
                        });
                        setPaymentTypeData(paymentTypeData);

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setPaymentTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            //   updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    const paymentStatusData = [{ paymentStatusId: 0, paymentStatus: 'Select All' }, { paymentStatusId: 1, paymentStatus: 'Full Paid' }, { paymentStatusId: 2, paymentStatus: 'Partial Paid' }, { paymentStatusId: 3, paymentStatus: 'Hold' }, { paymentStatusId: 4, paymentStatus: 'Un Paid' }]

    function selectPaymentStatus(e: any) {
        setDataSuccess(false);
        setPaymentTypeId('')
        let paymentStatusId = e
        setPaymentStatusId(paymentStatusId)
    }

    function selectPaymentType(e: any) {
        setDataSuccess(false);
        let paymentTypeId = e
        setPaymentTypeId(paymentTypeId)
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <PageWrapper title='Salary Report'>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card
                        stretch
                        data-tour='list'
                        tag='form'
                        noValidate
                        onSubmit={payRunForm.handleSubmit}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    Salary Report
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row g-4'>
                                <div className='col-3'>
                                    <FormGroup id='monthAndYear' label='Month' isFloating>
                                        <Input
                                            onChange={payRunForm.handleChange}
                                            value={payRunForm.values.monthAndYear}
                                            onBlur={payRunForm.handleBlur}
                                            isValid={payRunForm.isValid}
                                            isTouched={payRunForm.touched.monthAndYear}
                                            invalidFeedback={payRunForm.errors.monthAndYear}
                                            type='month'
                                        />
                                    </FormGroup>
                                </div>

                                {payRunForm.values.monthAndYear != '' ?
                                    <div className='col-3'>
                                        <FormGroup
                                            id='paymentStatus'
                                            label='Payment Status'
                                            isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Payment Status'
                                                onChange={(e: any) => selectPaymentStatus(e)}
                                                value={paymentStatusId}
                                                isValid={payRunForm.isValid}
                                                onBlur={payRunForm.handleBlur}
                                                isTouched={payRunForm.touched.paymentStatusId}
                                                invalidFeedback={payRunForm.errors.paymentStatusId}
                                                list={paymentStatusData.map((data: any) => (
                                                    { value: data.paymentStatusId, label: data.paymentStatus }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div>
                                    : null}

                                {paymentStatusId?.value != undefined ?
                                    <>{paymentStatusId?.value != 4 ?
                                        <div className='col-3'>
                                            <FormGroup id="paymentTypeId" label="Payment Type" isFloating>
                                                <SearchableSelect isFloating
                                                    ariaLabel='Payment Type'
                                                    onChange={(e: any) => selectPaymentType(e)}
                                                    value={paymentTypeId}
                                                    isValid={payRunForm.isValid}
                                                    onBlur={payRunForm.handleBlur}
                                                    isTouched={payRunForm.touched.paymentTypeId}
                                                    invalidFeedback={payRunForm.errors.paymentTypeId}
                                                    list={paymentTypeData.map((data: any) => (
                                                        { value: data.paymentTypeId, label: data.paymentType }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div>
                                        : <div className='col-3 align-self-center'>
                                            <Button
                                                icon='ArrowDownward'
                                                color='primary'
                                                type='submit'
                                                onClick={viewSalaryDetails}>
                                                View
                                            </Button>
                                        </div>}
                                    </> : null}


                                {paymentStatusId?.value != undefined && paymentTypeId?.value != undefined ?
                                    <div className='col-3'>
                                        <Button
                                            className='mt-2'
                                            icon='ArrowDownward'
                                            color='primary'
                                            type='submit'
                                            onClick={viewSalaryDetails} isDisable={!payRunForm.isValid && !!payRunForm.submitCount}>
                                            View
                                        </Button>
                                    </div>
                                    : null}
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent
                    isOpenListCard={isOpenListCard}
                    setIsOpenListCard={setIsOpenListCard}
                    isLoader={isLoader}
                />

                {dataSuccess ? (
                    <Card stretch ref={componentRef}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className='col-lg-6'>
                                <CardTitle tag='div' className='h5'>
                                    <span className='text-primary'>{convertNumToMonth(month)} - {year}</span> Salary Details
                                </CardTitle>
                            </CardLabel>
                            <CardActions className='d-print-none'>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'>
                                            Export
                                        </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel('SalaryReport', salaryDetails, columnVisibilityData)}>
                                                Excel
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(salaryDetails, columnVisibilityData, 'SalaryReport')}>
                                                PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile('SalaryReport', salaryDetails, columnVisibilityData)}>
                                                CSV
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={() => handlePrint()}>
                                                Print
                                            </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </CardActions>
                        </CardHeader>

                        <CardBody>
                            <div className='row g-4 align-items-center justify-content-center'>
                                <div className='col-xl-3'>
                                    <div
                                        className={classNames(
                                            'd-flex align-items-center rounded-2 p-3',
                                            {
                                                'bg-l10-secondary': !darkModeStatus,
                                                'bg-lo25-secondary': darkModeStatus,
                                            },
                                        )}>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='Group' size='3x' color='secondary' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>{totalStaffCount}</div>
                                            <div className='text-muted mt-n1 truncate-line-1'>
                                                Total Staff
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-3'>
                                    <div
                                        className={classNames(
                                            'd-flex align-items-center rounded-2 p-3',
                                            {
                                                'bg-l10-success': !darkModeStatus,
                                                'bg-lo25-success': darkModeStatus,
                                            },
                                        )}>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='Checklist' size='3x' color='success' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>{priceFormat(totalSalaryAmount)}</div>
                                            <div className='text-muted mt-n1 truncate-line-1'>
                                                Total Salary
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-3'>
                                    <div
                                        className={classNames(
                                            'd-flex align-items-center rounded-2 p-3',
                                            {
                                                'bg-l10-primary': !darkModeStatus,
                                                'bg-lo25-primary': darkModeStatus,
                                            },
                                        )}>
                                        <div className='flex-shrink-0'>
                                            <Icon
                                                icon='Money'
                                                size='3x'
                                                color='primary'
                                            />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'> {priceFormat(totalPaidSalaryAmount)}</div>
                                            <div className='text-muted mt-n1 truncate-line-1'>
                                                Total Paid Salary Amount
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-3'>
                                    <div
                                        className={classNames(
                                            'd-flex align-items-center rounded-2 p-3',
                                            {
                                                'bg-l10-danger': !darkModeStatus,
                                                'bg-lo25-danger': darkModeStatus,
                                            },
                                        )}>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='Report' size='3x' color='danger' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>{priceFormat(totalUpPaidSalaryAmount)}</div>
                                            <div className='text-muted mt-n1 truncate-line-1'>
                                                Total Un Payable Amount
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row g-4 mt-2'>
                                <table className='table table-modern table-hover mt-2'>
                                    <thead>
                                        <tr className='table-primary'>
                                            <td className='text-decoration-underline'>
                                                <strong>S.No</strong>
                                            </td>
                                            <td className='text-decoration-underline'>
                                                <strong>Staff Name</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>Total <br />Salary</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>
                                                    Total<br /> Days
                                                </strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>Monthly Salary <br />(Paid Days)</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>OT <br /> (Timing)</strong>
                                            </td>
                                            {/* <td className='text-center text-decoration-underline'>
                                                <strong>Late Come <br /> (Timing)</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>Lunch Late Come <br /> (Timing)</strong>
                                            </td> */}
                                            <td className='text-center text-decoration-underline'>
                                                <strong>Salary<br /> Advance</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>Loan</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>PF  <br /> Amount</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>LOP  <br /> Amount</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>Paid<br /> Amount</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>Pending<br /> Amount</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>Status</strong>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {salaryDetails != '' ? (
                                            <>
                                                {salaryDetails.map(
                                                    (salary: any, index: any) => (
                                                        <tr key={index}>
                                                            <td>{salary.sno}</td>
                                                            <td>{salary.staffName} <br /> <span className='text-center text-info'>{salary.empNumber}</span></td>
                                                            <td className='text-center text-success fw-bold text-decoration-underline' style={{ cursor: 'pointer' }} onClick={() => window.open(`../salary/payslip/${payRunForm.values.monthAndYear}/${salary.staffDetailsId}`)}>
                                                                {salary.totalSalaryAmount}</td>
                                                            <td className='text-center'>{salary.totalWorkingDays}</td>
                                                            <td className='text-center'><span className='text-success fw-bold'>{salary.monthlySalaryAmount}</span><br />(<span className='text-dark fw-bold'>{salary.totalPaidDays}</span>)</td>

                                                            <td className='text-center'><span className='text-success'>{salary.totalOtAmount}</span> <br />{salary.totalOtWorkingTimeForView != '' && salary.totalOtWorkingTimeForView != null ? <>(<span className='text-info'>{salary.totalOtWorkingTimeForView}</span>) </> : null}</td>

                                                            {/* <td className='text-center'><span className='text-danger'>{salary.lateComeDeduction}</span> <br />{salary.totalLateComeForView != '' && salary.totalLateComeForView != null ? <>(<span className='text-info'>{salary.totalLateComeForView}</span>)</> : null}</td> */}

                                                            {/* <td className='text-center'><span className='text-danger'>{salary.lunchLateComeDeduction}</span><br />{salary.totalLunchLateComeForView != '' && salary.totalLunchLateComeForView != null ? <>(<span className='text-info'>{salary.totalLunchLateComeForView}</span>)</> : null}</td> */}

                                                            <td className='text-center text-danger'>{salary.salaryAdvanceAmount}</td>
                                                            <td className='text-center text-danger'>{salary.loanAmount}</td>
                                                            <td className='text-center text-danger'>{salary.getTotalPfAmount}</td>
                                                            <td className='text-center text-danger'>{salary.getLopAmount}</td>
                                                            <td className='text-center text-success fw-bold'>{salary.paidAmount}</td>
                                                            <td className='text-center text-danger fw-bold'>{salary.pendingAmount}</td>{salary.paymentStatusId == 0 ?
                                                                <td className='text-center text-danger fw-bold'>{salary.paymentStatus}</td> :
                                                                salary.paymentStatusId == 1 ?
                                                                    <td className='text-center'><span className='text-success fw-bold'>{salary.paymentStatus} </span>{salary.paymentTypeIds != '' ? <span className='h6'><br />({salary.paymentTypes})</span> : null}
                                                                    </td>
                                                                    : salary.paymentStatusId == 2 ?
                                                                        <td className='text-center'><span className='text-success fw-bold'>{salary.paymentStatus} </span>{salary.paymentTypeIds != '' ? <span className='h6'><br />({salary.paymentTypes})</span> : null}</td> :
                                                                        <td className='text-center text-danger fw-bold'>{salary.paymentStatus}</td>}
                                                        </tr>
                                                    )
                                                )}
                                            </>
                                        ) : (
                                            <NoDataMsg columnsCount={13} msg={noDataMsg} />
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                ) : null}
            </Page>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper>
    );
};

export default SalaryReport;
