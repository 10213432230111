import React, { useContext, useEffect, useRef, useState } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import Icon from '../icon/Icon';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../bootstrap/Dropdown';
import FormGroup from '../bootstrap/forms/FormGroup';
import { useReactToPrint } from 'react-to-print';
import Button from '../bootstrap/Button';
import { useFormik } from 'formik';
import Input from '../bootstrap/forms/Input';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../contexts/authContext';
import useSortableData from '../../hooks/useSortableData';
import { dataPagination } from '../PaginationButtons';
import Collapse from '../bootstrap/Collapse';
import OpenCardComponent from '../../common/components/OpenCardComponents';
import { convertNumToMonth, getColumnsForDataTable, getPaymentType, onlyAllowNumber } from '../../services/common.service';
import NoDataMsg from '../../common/components/NoDataMsg';
import { toasts } from '../../services/toast.service';
import { getLicenseKey } from '../../services/application.settings';
import AlertService from '../../services/AlertService';
import useMinimizeAside from '../../hooks/useMinimizeAside';
import { convertJsonToPdf, downloadExcel, downloadFile } from '../../services/export.service';
import '../../common/components/toPrint.css'
import useDarkMode from '../../hooks/useDarkMode';
import { getMonthlySalaryReport } from '../../services/report.service';
import SearchableSelect from '../../common/components/SearchableSelect';

const MonthlySalaryReport = () => {

    useMinimizeAside();
    // useEffect(() => {
    // }, []);

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const { items, requestSort, getClassNamesFor } = useSortableData([]);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [isOpenListCard, setIsOpenListCard] = useState(true);
    const [isLoader, setIsLoader] = useState(false);
    const [dataSuccess, setDataSuccess] = useState(false);
    const [monthlySalaryData, setMonthlySalaryData] = useState<any>([]);
    const [noDataMsg, setNoDataMsg] = useState('');
    const [fromMonth, setFromMonth] = useState('');
    const [fromYear, setFromYear] = useState('');
    const [toMonth, setToMonth] = useState('');
    const [toYear, setToYear] = useState('');
    const componentRef = useRef(null);
    const navigate = useNavigate();

    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [columnDataById, setColumnDataById] = useState([]);

    const [paymentStatusId, setPaymentStatusId] = useState<any>('')

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const paymentStatusData = [{ paymentStatusId: 0, paymentStatus: 'Select All' }, { paymentStatusId: 1, paymentStatus: 'Full Paid' }, { paymentStatusId: 2, paymentStatus: 'Partial Paid' }, { paymentStatusId: 3, paymentStatus: 'Hold' }, { paymentStatusId: 4, paymentStatus: 'Un Paid' }]


    const payRunForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            fromMonthAndYear: '',
            toMonthAndYear: '',
        },
        validate: (values) => {
            const errors: {
                fromMonthAndYear?: string;
                toMonthAndYear?: string;
                paymentTypeId?: string;
                paymentStatusId?: string;
            } = {};

            if (!values.fromMonthAndYear) {
                errors.fromMonthAndYear = 'Required';
            }
            if (!values.toMonthAndYear) {
                errors.toMonthAndYear = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { viewSalaryDetails() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    function selectPaymentStatus(e: any) {
        setDataSuccess(false);
        let paymentStatusId = e
        setPaymentStatusId(paymentStatusId)
    }

    function viewSalaryDetails() {
        setDataSuccess(false);
        setIsLoader(true);
        let from = payRunForm.values.fromMonthAndYear;
        let to = payRunForm.values.toMonthAndYear;
        const fromSplitDate = from.split('-');
        const toSplitDate = to.split('-');
        const fromYear = fromSplitDate[0];
        const fromMonth = fromSplitDate[1];
        const toYear = toSplitDate[0];
        const toMonth = toSplitDate[1];

        setFromYear(fromYear);
        setFromMonth(fromMonth);
        setToYear(toYear);
        setToMonth(toMonth);
        getMonthlySalaryList(fromYear, fromMonth, toYear, toMonth, paymentStatusId?.value);
    }

    function getMonthlySalaryList(fromYear: any, fromMonth: any, toYear: any, toMonth: any, paymentStatusId: any) {
        setIsLoader(true);
        getMonthlySalaryReport(fromYear, fromMonth, toYear, toMonth, paymentStatusId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.monthlySalaryReport;
                    if (data != undefined) {
                        setMonthlySalaryData(data);
                        getColumnsForTable('getMonthlySalaryReport', 'get');
                        setIsLoader(false);
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        setDataSuccess(false);
                        setIsOpenListCard(false);
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setIsLoader(false);
                    getColumnsForTable('getMonthlySalaryReport', 'get');
                    setMonthlySalaryData([]);
                    setDataSuccess(true);
                    setIsOpenListCard(false);
                    setNoDataMsg(response.data.message);
                }
            },
            (error) => {
                setIsLoader(false);
                toasts(error, 'Error');
            },
        );
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            //   updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <PageWrapper title='Monthly Wise Salary Report'>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card
                        stretch
                        data-tour='list'
                        tag='form'
                        noValidate
                        onSubmit={payRunForm.handleSubmit}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    Monthly Wise Salary Report
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row g-4'>
                                <div className='col-2'>
                                    <FormGroup id='fromMonthAndYear' label='From Month' isFloating>
                                        <Input
                                            onChange={payRunForm.handleChange}
                                            value={payRunForm.values.fromMonthAndYear}
                                            onBlur={payRunForm.handleBlur}
                                            isValid={payRunForm.isValid}
                                            isTouched={payRunForm.touched.fromMonthAndYear}
                                            invalidFeedback={payRunForm.errors.fromMonthAndYear}
                                            type='month'
                                        />
                                    </FormGroup>
                                </div>

                                {payRunForm.values.fromMonthAndYear != '' ?
                                    <div className='col-2'>
                                        <FormGroup id='toMonthAndYear' label='To Month' isFloating>
                                            <Input
                                                onChange={payRunForm.handleChange}
                                                value={payRunForm.values.toMonthAndYear}
                                                onBlur={payRunForm.handleBlur}
                                                isValid={payRunForm.isValid}
                                                isTouched={payRunForm.touched.toMonthAndYear}
                                                invalidFeedback={payRunForm.errors.toMonthAndYear}
                                                type='month'
                                            />
                                        </FormGroup>
                                    </div>
                                    : null}

                                {payRunForm.values.toMonthAndYear != '' ?
                                    <div className='col-3'>
                                        <FormGroup
                                            id='paymentStatus'
                                            label='Payment Status'
                                            isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Payment Status'
                                                onChange={(e: any) => selectPaymentStatus(e)}
                                                value={paymentStatusId}
                                                list={paymentStatusData.map((data: any) => (
                                                    { value: data.paymentStatusId, label: data.paymentStatus }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div>
                                    : null}

                                {paymentStatusId?.value != undefined ?
                                    <div className='col-2'>
                                        <Button
                                            className='mt-2'
                                            icon='ArrowDownward'
                                            color='primary'
                                            type='submit'
                                            isDisable={!payRunForm.isValid && !!payRunForm.submitCount}>
                                            View
                                        </Button>
                                    </div> : null}
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent
                    isOpenListCard={isOpenListCard}
                    setIsOpenListCard={setIsOpenListCard}
                    isLoader={isLoader}
                />

                {dataSuccess ? (
                    <Card stretch ref={componentRef}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className='col-lg-6'>
                                <CardTitle tag='div' className='h5'>
                                    <span className='text-primary'>{convertNumToMonth(fromMonth)} - {fromYear}</span>  To  <span className='text-primary'>{convertNumToMonth(toMonth)} - {toYear}</span> Monthly Wise Salary Details
                                </CardTitle>
                            </CardLabel>
                            <CardActions className='d-print-none'>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'>
                                            Export
                                        </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel('MonthlyWiseSalaryReport', monthlySalaryData, columnVisibilityData)}>
                                                Excel
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(monthlySalaryData, columnVisibilityData, 'MonthlyWiseSalaryReport')}>
                                                PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile('MonthlyWiseSalaryReport', monthlySalaryData, columnVisibilityData)}>
                                                CSV
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={() => handlePrint()}>
                                                Print
                                            </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </CardActions>
                        </CardHeader>

                        <CardBody>

                            <div className='row g-4 mt-2'>
                                <table className='table table-modern table-hover mt-2'>
                                    <thead>
                                        <tr className='table-primary'>
                                            <td className='text-decoration-underline'>
                                                <strong>S.No</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>Year <br /> Month</strong>
                                            </td>
                                            <td className='text-decoration-underline'>
                                                <strong>Staff Name</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>Total <br />Salary</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>
                                                    Total<br /> Days
                                                </strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>Monthly Salary <br />(Paid Days)</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>OT <br /> (Timing)</strong>
                                            </td>
                                            {/* <td className='text-center text-decoration-underline'>
                                                <strong>Late Come <br /> (Timing)</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>Lunch Late Come <br /> (Timing)</strong>
                                            </td> */}
                                            <td className='text-center text-decoration-underline'>
                                                <strong>Salary<br /> Advance</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>Loan</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>PF  <br /> Amount</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>LOP  <br /> Amount</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>Paid<br /> Amount</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>Pending<br /> Amount</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>Status</strong>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {monthlySalaryData != '' ? (
                                            <>
                                                {monthlySalaryData.map(
                                                    (salary: any, index: any) => (
                                                        <tr key={index}>
                                                            <td>{salary.sno}</td>
                                                            <td className='text-center fw-bold'>
                                                                {salary.year} <br /> {convertNumToMonth(salary.month)}</td>
                                                            <td>{salary.staffName} <br /> <span className='text-center text-info'>{salary.empNumber}</span></td>
                                                            <td className='text-center text-success fw-bold'>
                                                                {salary.totalSalaryAmount}</td>
                                                            <td className='text-center'>{salary.totalWorkingDays}</td>
                                                            <td className='text-center'><span className='text-success fw-bold'>{salary.monthlySalaryAmount}</span><br />(<span className='text-dark fw-bold'>{salary.totalPaidDays}</span>)</td>

                                                            <td className='text-center'><span className='text-success'>{salary.totalOtAmount}</span> <br />{salary.totalOtWorkingTimeForView != '' && salary.totalOtWorkingTimeForView != null ? <>(<span className='text-info'>{salary.totalOtWorkingTimeForView}</span>) </> : null}</td>

                                                            {/* <td className='text-center'><span className='text-danger'>{salary.lateComeDeduction}</span> <br />{salary.totalLateComeForView != '' && salary.totalLateComeForView != null ? <>(<span className='text-info'>{salary.totalLateComeForView}</span>)</> : null}</td> */}

                                                            {/* <td className='text-center'><span className='text-danger'>{salary.lunchLateComeDeduction}</span><br />{salary.totalLunchLateComeForView != '' && salary.totalLunchLateComeForView != null ? <>(<span className='text-info'>{salary.totalLunchLateComeForView}</span>)</> : null}</td> */}

                                                            <td className='text-center text-danger'>{salary.salaryAdvanceAmount}</td>
                                                            <td className='text-center text-danger'>{salary.loanAmount}</td>
                                                            <td className='text-center text-danger'>{salary.getTotalPfAmount}</td>
                                                            <td className='text-center text-danger'>{salary.getLopAmount}</td>
                                                            <td className='text-center text-success fw-bold'>{salary.paidAmount}</td>
                                                            <td className='text-center text-danger fw-bold'>{salary.pendingAmount}</td>{salary.paymentStatusId == 0 ?
                                                                <td className='text-center text-danger fw-bold'>{salary.paymentStatus}</td> :
                                                                salary.paymentStatusId == 1 ?
                                                                    <td className='text-center'><span className='text-success fw-bold'>{salary.paymentStatus} </span>
                                                                    </td>
                                                                    : salary.paymentStatusId == 2 ?
                                                                        <td className='text-center'><span className='text-success fw-bold'>{salary.paymentStatus} </span></td> :
                                                                        <td className='text-center text-danger fw-bold'>{salary.paymentStatus}</td>}
                                                        </tr>
                                                    )
                                                )}
                                            </>
                                        ) : (
                                            <NoDataMsg columnsCount={15} msg={noDataMsg} />
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                ) : null}
            </Page>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper>
    );
};

export default MonthlySalaryReport;
